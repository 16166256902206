import Css from "./style.module.scss";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import Pages from "lib/pages/Pages";
import React, { Fragment, PureComponent } from "react";

const mapStateToProps = (state) => ({
  selectedBusinessData: getSelectedBusinessData(state),
  activeOrganization: getActiveOrganization(state),
  projectName: getProjectName(state),
  textsData: getTextsData(state),
  userRole: getUserRole(state)
});

@withRouter
@connect(mapStateToProps)
class PageHeader extends PureComponent {
  get currentRouteInfo() {
    return Pages.getCurrentRouteInfo(this.props.location.pathname);
  }

  componentDidMount() {
    const { textsData, userRole, projectName, activeOrganization: { countryCode } } = this.props;

    const { titleLangId } = Pages.getPageData([userRole, this.currentRouteInfo.route, countryCode]);

    const pageTitle = textsData.uiTexts[titleLangId];

    window.document.title = `${pageTitle} | ${projectName}`;
  }

  render() {
    const {
      userRole,
      selectedBusinessData,
      fullPath,
      subTitle,
      children,
      textsData: { uiTexts },
      activeOrganization: { countryCode }
    } = this.props;

    const { titleLangId, iconComponent: Icon } = Pages.getPageData([userRole, this.currentRouteInfo.route, countryCode]);

    return (
      <header className={Css.pageHeader}>
        <div className={Css.leftBlock}>
          <div className={Css.subTitle}>{subTitle || selectedBusinessData.name}</div>
          <div className={Css.title}>
            <Icon />
            {fullPath
              ? (
                <span>
                  {[uiTexts[titleLangId], ...this.currentRouteInfo.restPath].map((segment, index, array) => {
                    const activeSegment = index === array.length - 1;

                    const segmentPath = [this.currentRouteInfo.route, ...this.currentRouteInfo.restPath].slice(0, index + 1);

                    const path = segmentPath.join("/");

                    return (
                      <Fragment key={path}>
                        {array.length === 1 || activeSegment
                          ? (<span disabled={array.length > 1}>{segment}</span>)
                          : (<Link to={`/${selectedBusinessData.id + path}`}>{segment}</Link>)}
                        {!activeSegment && (<span className={Css.separator}>/</span>)}
                      </Fragment>
                    );
                  })}
                </span>
              )
              : (<span>{uiTexts[titleLangId]}</span>)}
          </div>
        </div>
        <div className={Css.rightBlock}>{children}</div>
      </header>
    );
  }
}

export default PageHeader;
